/**
 * Timetastic
 * Sign up button component
 */
import React from "react"

// Helpers
import { trackCta } from "src/helpers"
import { doSignupClick } from "../../helpers/analytics"

// Styles
//  import "./freeTrial.scss"

// JSX
const SignupButton = ({
    buttonCopy,
    trackCtaLabel,
    startWithPro
}) => {
    return (
        <a
            className="c-button c-button--primary c-button--xl"
            href="/pricing/"
            onClick={() => {
                doSignupClick(startWithPro)
                trackCta(trackCtaLabel)
            }}
        >
            {buttonCopy}
        </a>)
}

export default SignupButton