/**
 * Timetastic
 * Free TrialAlt component
 */
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { doSignupClick } from "../../helpers/analytics"

// Helpers
import { trackCta } from "src/helpers"

// Styles
import "./freeTrialAlt.scss"

// JSX
const FreeTrialAlt = ({
  body,
  buttonCopy,
  buttonHref,
  title,
  trackCtaLabel,
}) => {
  const isInternalHref = buttonHref.startsWith("/")
  const buttonClass = "c-button c-button--xl c-button--primary"

  return (
    <section className="is-unanimated c-section c-free-trial-alt">
      <div className="u-inner u-inner--l c-free-trial-alt__grid">
        <div>
          <h2 className="font-l c-free-trial-alt__title">{title}</h2>
          <div className="c-free-trial-alt__copy">
            <p>{body}</p>
          </div>
        </div>
        <div className="u-text-center u-text-left-mobile">
          {/* Check to see if its an external (or internal) link and use `<Link>` accordingly */}

          {isInternalHref && (
            <Link
              className={buttonClass}
              to={buttonHref}
              onClick={() => {
                trackCta(trackCtaLabel)
              }}
            >
              {buttonCopy}
            </Link>
          )}
          {!isInternalHref && (
            <a
              className={buttonClass}
              href={buttonHref}
              onClick={() => {
                trackCta(trackCtaLabel)
                doSignupClick()
              }}
            >
              {buttonCopy}
            </a>
          )}
          <div className="c-free-trial__no-link">
            No obligation, no credit card required.
          </div>
        </div>
      </div>
    </section>
  )
}

FreeTrialAlt.propTypes = {
  body: PropTypes.string.isRequired,
  buttonCopy: PropTypes.string,
  buttonHref: PropTypes.string,
  title: PropTypes.string,
  trackCtaLabel: PropTypes.string,
}

FreeTrialAlt.defaultProps = {
  buttonCopy: "Start free trial",
  buttonHref: "/pricing/",
  title: "Get one month free!",
  trackCtaLabel: "SignUp",
}

export default FreeTrialAlt
