/**
 * Timetastic
 * Sticky footer CTA component
 */
import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { useInView } from "react-intersection-observer"
import { Link } from "gatsby"

// Helpers
import { trackCta } from "src/helpers"

// Components
import SignupButton from "src/components/signupButton"

// Styles
import "./footerCta.scss"
import "/src/styles/animations/home.scss"

// JSX
const FooterCta = ({ buttonCopy, buttonHref, trackCtaLabel }) => {
  const isInternalHref = buttonHref.startsWith("/")
  const buttonClass = "c-button c-button--xl c-button--primary"
  const { ref: ctaRef, inView: showFooterCta } = useInView()

  return showFooterCta ? (
    <div className="u-inner u-inner--full">
      <div className="c-footer__signup">
        {/* Check to see if its an external (or internal) link and use `<Link>` accordingly */}

        {isInternalHref && (
          <Link
            className={buttonClass}
            to={buttonHref}
            onClick={() => {
              trackCta(trackCtaLabel)
            }}
          >
            {buttonCopy}
          </Link>
        )}
        {!isInternalHref && (
          <a
            className={buttonClass}
            href={buttonHref}
            onClick={() => {
              trackCta(trackCtaLabel)
              doSignupClick()
            }}
          >
            {buttonCopy}
          </a>
        )}
      </div>
    </div>
  ) : (
    <div className="c-footer__signup__anchor" ref={ctaRef}></div>
  )
}

FooterCta.propTypes = {
  buttonCopy: PropTypes.string,
  buttonHref: PropTypes.string,
  trackCtaLabel: PropTypes.string,
}

FooterCta.defaultProps = {
  buttonCopy: "Pricing & free trial",
  buttonHref: "/pricing/",
  trackCtaLabel: "stickyFooterCta",
}

export default FooterCta
