/**
 * Timetastic
 * Footer component
 */
import React from "react"

// Helpers
import { trackCta } from "src/helpers"

// Styles
import "./caseStudies.scss"

// JSX
const CaseStudies = () => (
  <div className="c-case-studies">
    <div className="c-case-study is-unanimated">
      <img
        alt="Raw Impact team"
        className="c-case-study__image"
        height="400"
        src="/images/customers/raw-impact.jpg"
        width="540"
        loading="lazy"
      />
      <p>
        Not many charities begin with a blind date. But that’s the story of RAW, a nonprofit working to empower vulnerable Cambodian families.
      </p>
      <p>
        <a
          aria-label="Read Raw Impact case study"
          className="c-case-study__more"
          href="https://timetastic.us/blog/raw-impact/"
          onClick={() => {
            trackCta("AboutCaseStudyRawImpact")
          }}
        >
          Read case study{" "}
          <svg
            aria-hidden="true"
            className="c-more-icon"
            focussable="false"
            height="16"
            viewBox="0 0 20 16"
            width="20"
          >
            <use xlinkHref="#svg-arrow" />
          </svg>
        </a>
      </p>
    </div>
    <div className="c-case-study is-unanimated">
      <img
        alt="Buffer team"
        className="c-case-study__image"
        height="400"
        src="/images/customers/buffer-team.jpg"
        width="540"
        loading="lazy"
      />
      <p>
        How Google Calendar and Slack integrations are helping Buffer with transparency and visibility.
      </p>
      <p>
        <a
          aria-label="Read Buffer case study"
          className="c-case-study__more"
          href="https://timetastic.us/blog/taking-time-off-transparently-at-buffer/"
          onClick={() => {
            trackCta("AboutCaseStudyBuffer")
          }}
        >
          Read case study{" "}
          <svg
            aria-hidden="true"
            className="c-more-icon"
            focussable="false"
            height="16"
            viewBox="0 0 20 16"
            width="20"
          >
            <use xlinkHref="#svg-arrow" />
          </svg>
        </a>
      </p>
    </div>
  </div>
)

export default CaseStudies
